import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { Alert, Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
// import Grid from '@mui/material/Grid2';
import ReCAPTCHA from 'react-google-recaptcha';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AccessTime, AttachMoneyOutlined, BrowseGalleryOutlined, Check, Close, FacebookOutlined, Instagram, InterestsOutlined, LinkedIn, PhoneOutlined, SentimentVerySatisfiedOutlined, SupportAgentOutlined } from '@mui/icons-material';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AgenteVirtual({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [boxMsg, setBoxMsg] = useState("");
  const [mensagemBox, setMensagemBox] = useState(false);
  const [nome, setNome] = useState("");
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [limiteCaracteres, setLimiteCaracteres] = useState("0/140");

  const [captchaValido, setCaptchaValido] = useState(false);
  const recaptchaRef = React.createRef();

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaValido(true);
      setBoxMsg("");
    }
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleNome = (event) => {
    setNome(event.target.value);
  };

  const handleNomeEmpresa = (event) => {
    setNomeEmpresa(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleTelefone = (event) => {
    setTelefone(event.target.value);
  };

  const handleMensagem = (event) => {
    const mensagem = event.target.value
    setLimiteCaracteres(`${mensagem.length}/140`)
    setMensagem(mensagem);
  };

  const handleContato = () => {
    onChange("", "contato")
  }

  const handleMsg = (tipo, mensagem) => {
    setBoxMsg(tipo)
    setMensagemBox(mensagem)
  }
  
  const handleMsgEmail = () => {
    handleOpenLoading()
    if (!nome) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha seu nome.`)
      return
    }
    if (!email) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha seu e-mail.`)
      return
    }
    if (!nomeEmpresa) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha o nome da sua empresa.`)
      return
    }
    if (!telefone) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha seu telefone.`)
      return
    }
    if (!mensagem) {
      handleCloseLoading()
      handleMsg("error", `Por favor preencha a mensagem.`)
      return
    }

    if (!captchaValido) {
      handleCloseLoading()
      handleMsg("error", `Por favor, confirme que você não é um robô.`)
      return
    }

    recaptchaRef.current.reset();

    const post_url = `https://elysolucoestecnologicas.com.br/api/request-contact.php?nome=${nome}&nomeempresa=${nomeEmpresa}&email=${email}&telefone=${telefone}&mensagem=${mensagem}`;

    axios.post(post_url)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "success") {
            handleCloseLoading()
            handleMsg("success", `Sua solicitação foi enviada com sucesso.`)
            setMensagem("")
            setNome("")
            setEmail("")
            setTelefone("")
            setNomeEmpresa("")
            return
          }
        }
        handleCloseLoading()
        handleMsg("error", `Não foi possivel enviar a sua solicitação. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => {
        console.log(error)
        handleCloseLoading()
        handleMsg("error", `Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
      });
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <LoadingComponent open={loading} />

      <div style={{ height: 100 }} />
      <Typography style={{ textAlign: "center", fontFamily: "roboto regular", fontSize: 64, fontWeight: "bold", color: "#2B3AE7" }}>
        Agente virtual
      </Typography>

      <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA" }}>
        Melhore a experiência do cliente com agentes virtuais
      </Typography>

      <Typography style={{ fontFamily: "roboto light", textAlign: "center" }}>
        <div style={{ height: 16 }} />
        <Button variant={"outlined"} onClick={handleContato} size="large" style={{ textTransform: 'none', margin: "auto", fontFamily: "roboto light", fontWeight: "bold" }}>
          Solicitar demonstração
        </Button>
      </Typography>

      <div style={{ height: 4 }} />

      <div style={{ padding: 26 }}>

        <div style={{ width: "100%", overflowX: "auto", display: "flex", whiteSpace: "nowrap", paddingBottom: 22 }}>
          <div style={{ display: "inline-block", minWidth: 290, width: "100%", marginRight: "16px" }}>
            <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
              <CardActionArea style={{ padding: 16 }}>
                <Typography style={{ textAlign: "center" }}>
                  <SupportAgentOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                </Typography>
                <CardContent style={{ textAlign: "left", padding: 0 }}>
                  <Typography style={{ color: "#575975", fontFamily: "roboto regular", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                    O Agente Virtual é uma solução completa automatizada para call centers, capaz de assumir grande parte do trabalho dos atendentes humanos.
                  </Typography>
                </CardContent>

              </CardActionArea>
            </Card>
          </div>

          <div style={{ display: "inline-block", minWidth: 290, width: "100%", marginRight: "16px" }}>
            <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
              <CardActionArea style={{ padding: 16 }}>

                <Typography style={{ textAlign: "center" }}>
                  <PhoneOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                </Typography>
                <CardContent style={{ textAlign: "left", padding: 0 }}>

                  <Typography style={{ color: "#575975", fontFamily: "roboto regular", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                    Ele realiza atendimentos telefônicos automatizados e gerencia milhares de ligações simultâneas, sempre mantendo um alto padrão de excelência.
                  </Typography>
                </CardContent>

              </CardActionArea>
            </Card>
          </div>

          <div style={{ display: "inline-block", minWidth: 290, width: "100%", paddingBottom: 22 }}>
            <Card variant="outlined" sx={{ borderRadius: "8px", background: "#FFFFFF00" }}>
              <CardActionArea style={{ padding: 16 }}>

                <Typography style={{ textAlign: "center" }}>
                  <BrowseGalleryOutlined style={{ fontSize: 70, color: "#2B3AE7" }} />
                </Typography>

                <CardContent style={{ textAlign: "left", padding: 0 }}>

                  <Typography style={{ color: "#575975", fontFamily: "roboto regular", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                    Equipes especializadas planejam, desenvolvem e aprimoram esses agentes, que operam 24 horas por dia de maneira eficiente e assertiva.
                  </Typography>
                </CardContent>

              </CardActionArea>
            </Card>
          </div>
        </div>

        <div style={{ height: 40 }} />

        <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA", fontWeight: "bold" }}>
          Por que escolher nossos agentes virtuais?
        </Typography>

        <div style={{ height: 16 }} />

        <Grid container spacing={2} style={{ padding: 0 }}>
          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <div style={{ border: "solid 1px #ccc", display: "flex", alignItems: "center", padding: 16, borderRadius: 8, marginTop: 16 }}>
              <AttachMoneyOutlined style={{ color: "#2B3AE7" }} /> &nbsp;&nbsp;&nbsp;
              <b style={{ fontFamily: "roboto light", textAlign: "center", color: "#999" }}>Redução de Custos</b>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <div style={{ border: "solid 1px #ccc", display: "flex", alignItems: "center", padding: 16, borderRadius: 8, marginTop: 16 }}>
              <AccessTime style={{ color: "#2B3AE7" }} />&nbsp;&nbsp;&nbsp;
              <b style={{ fontFamily: "roboto light", textAlign: "center", color: "#999" }}>Disponibilidade 24/7</b>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <div style={{ border: "solid 1px #ccc", display: "flex", alignItems: "center", padding: 16, borderRadius: 8, marginTop: 16 }}>
              <SentimentVerySatisfiedOutlined style={{ color: "#2B3AE7" }} />&nbsp;&nbsp;&nbsp;
              <b style={{ fontFamily: "roboto light", textAlign: "center", color: "#999" }}>Personalização Inteligente</b>
            </div>
          </Grid>
        </Grid>
        <div style={{ height: 80 }} />

        <Typography style={{ fontFamily: "roboto light", textAlign: "center", fontSize: 33, color: "#ABACBA", fontWeight: "bold" }}>
          Recursos
        </Typography>

        <div style={{ height: 24 }} />

        <Grid container spacing={2} style={{ padding: 0 }}>
          <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 0 }}>
            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Escala de relacionamento &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Satisfação garantida &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Redução de custos &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Atendimento profissional &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Operação 24/7 &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Menos ligações perdidas &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Processos personalizados &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />

            <Chip
              style={{ fontSize: 18, background: "#2B3AE7", color: "#fff", padding: 20, margin: 5, borderRadius: 8 }}
              label={<Typography style={{ display: "flex", alignItems: "center" }}>Otimização de chamadas &nbsp;&nbsp;<Check style={{ color: "#fff", marginTop: -4 }} /></Typography>}
            />
          </Grid>
        </Grid>

        <div style={{ height: 80 }} />

        <div style={{ background: "#E3E3E8", borderRadius: 24 }}>
          <Grid container spacing={2} style={{ padding: 32 }}>
            <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>
              <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                <Typography style={{ color: "#2B3AE7", fontFamily: "roboto regular", fontSize: 32, fontWeight: "bold" }}>
                  Entre em contato conosco
                  <div style={{ height: 6 }} />
                  <b style={{ fontWeight: "normal", fontSize: 22, color: "#1c204a" }}>Preencha as informações e entraremos em contato com você!</b>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>

              {boxMsg &&
                <>
                  <Alert icon={boxMsg === "sucesso" ? <Check fontSize="inherit" /> : <Close fontSize="inherit" />} severity={boxMsg} onClick={() => setBoxMsg("")}>
                    {mensagemBox}
                  </Alert>
                  <div style={{ height: 20 }} />
                </>
              }

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Nome <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={nome}
                    onChange={handleNome}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu nome"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    E-mail <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={email}
                    onChange={handleEmail}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu e-mail"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Nome da Empresa <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={nomeEmpresa}
                    onChange={handleNomeEmpresa}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o nome da sua empresa"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Telefone <b style={{ color: "#A51D1D" }}>*</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={telefone}
                    onChange={handleTelefone}
                    variant="outlined"
                    size="large"
                    placeholder="Insira o seu telefone"
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                    Mensagem <b style={{ color: "#A51D1D" }}>*</b>
                    <b style={{ float: "right", color: "#999", fontWeight: "normal" }}>{limiteCaracteres}</b>
                  </Typography>
                  <div style={{ height: 4 }}></div>
                  <TextField
                    fullWidth
                    hiddenLabel
                    value={mensagem}
                    onChange={handleMensagem}
                    variant="outlined"
                    size="large"
                    inputProps={{ maxLength: 140 }}
                    multiline
                    rows={4}
                    placeholder="Insira sua mensagem aqui..."
                    sx={{ background: "#fff" }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfPU2IqAAAAALWtDVY12aKBEoEuWIGNL321intB"
                    onChange={handleCaptchaChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button variant={"contained"} size="large" style={{ textTransform: 'none', margin: "auto", fontFamily: "roboto light", fontWeight: "bold", minWidth: 140 }} onClick={handleMsgEmail}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div style={{ height: 80 }} />
      </div>

      <div style={{ background: "#1C204A" }}>
        <Grid container spacing={2} style={{ padding: 32 }}>
          <Grid item xs={12} sm={12} md={3} style={{ paddingTop: 0 }}>
            <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-white.png" style={{ width: 200, marginLeft: "auto" }} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", height: "100%" }}>
              Ely Soluções Tecnológicas LTDA
              CNPJ 43.363.160.0001-08
              <br /><br />
              @2021-2023 Todos os Direitos Reservados
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5} style={{ paddingTop: 0 }}>
            <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", height: "100%" }}>
              <label><b>Informações</b><br /><br />
                <b>Endereço</b><br />Travessa Dona Paula, 13, Higienópolis
                São Paulo-SP, 01239-050
                <br /><br />
                <b>Contato</b><br />relacionamento@elysolucoestecnologicas.com.br
              </label>
            </Typography>
          </Grid>
        </Grid>
      </div>

    </ThemeProvider >
  );
}

export default AgenteVirtual;