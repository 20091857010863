import { Alert, AppBar, Avatar, Box, Button, Divider, Fade, FormControl, IconButton, InputAdornment, ListItemButton, ListItemIcon, Menu, MenuItem, Modal, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react';
import { ExpandMoreOutlined, MenuOutlined, PhoneOutlined  } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `none`,
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    paddingLeft: 0,
    marginTop: -4,
    borderRadius: 6,
    backgroundColor: "#f8f8f8",
}));

const SideMenu = ({ onChange, update, colorPrimary }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [currentPage, setCurrentPage] = useState("home");
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openSolucoes = Boolean(anchorEl);

    const handleOpenSolucoes = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSolucoes = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const changePage = (page) => {
        handleCloseSolucoes()
        onChange("", page);
        setCurrentPage(page);
        setOpen(false)
    }

    return (
        <>
            <AppBar position="absolute" style={{ backgroundColor: `#ededf6`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto", height: 75, display: "flex", justifyContent: "center" }}>
                <Toolbar>

                    {isMobile ?
                        <>
                            <IconButton size="large" color="inherit" style={{ color: "#2B3AE7" }} onClick={() => setOpen(true)}>
                                <MenuOutlined style={{ fontSize: 42 }} />
                            </IconButton>
                            <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-blue.png" style={{ width: 100, marginLeft: "auto" }} />
                        </>
                        :
                        <>
                            <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-blue.png" style={{ width: 100 }} />
                            <Button variant="outlined" style={{ textTransform: 'none', marginLeft: "auto", background: currentPage === "home" ? "#8e94f1" : "", color: currentPage === "home" ? "#fff" : "", border: currentPage === "home" ? "none" : "" }} onClick={() => changePage("home")}>
                                Home
                            </Button>
                            <Button variant="outlined" style={{ textTransform: 'none', marginLeft: 10, background: currentPage === "sobre" ? "#8e94f1" : "", color: currentPage === "sobre" ? "#fff" : "", border: currentPage === "sobre" ? "none" : "" }} onClick={() => changePage("sobre")}>
                                Sobre nós
                            </Button>
                            <Button variant="outlined" style={{ textTransform: 'none', marginLeft: 10, background: currentPage == "omnichannel" || currentPage == "discador" || currentPage == "agente" ? "#8e94f1" : "", color: currentPage == "omnichannel" || currentPage == "discador" || currentPage == "agente" ? "#fff" : "", border: currentPage == "omnichannel" || currentPage == "discador" || currentPage == "agente" ? "none" : "" }} onClick={handleOpenSolucoes} endIcon={<ExpandMoreOutlined />}>
                                Soluções
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={openSolucoes}
                                onClose={handleCloseSolucoes}
                                PaperProps={{
                                    style: {
                                      boxShadow: 'none',
                                      marginTop: '10px',
                                    }
                                  }}
                            >
                                <MenuItem style={{ fontWeight: "bold", fontSize: 14, marginLeft: 8, marginRight: 8, background: currentPage === "omnichannel" ? "#8e94f1" : "", color: currentPage === "omnichannel" ? "#fff" : "#1C204A", borderRadius: 6 }} onClick={() => changePage("omnichannel")}>Omnichannel</MenuItem>
                                <MenuItem style={{ fontWeight: "bold", fontSize: 14, margin: 8, background: currentPage === "discador" ? "#8e94f1" : "", color: currentPage === "discador" ? "#fff" : "#1C204A", borderRadius: 6 }} onClick={() => changePage("discador")}>Discador</MenuItem>
                                <MenuItem style={{ fontWeight: "bold", fontSize: 14, marginLeft: 8, marginRight: 8, background: currentPage === "agente" ? "#8e94f1" : "", color: currentPage === "agente" ? "#fff" : "#1C204A", borderRadius: 6 }} onClick={() => changePage("agente")}>Agente Virtual</MenuItem>
                            </Menu>
                            <Button variant={currentPage === "contato" ? "outlined" : "contained"} style={{ textTransform: 'none', marginLeft: 10, background: currentPage === "contato" ? "#8e94f1" : "", color: currentPage === "contato" ? "#fff" : "", border: currentPage === "contato" ? "none" : "" }} onClick={() => changePage("contato")} startIcon={<PhoneOutlined />}>
                                Contato
                            </Button>
                        </>
                    }
                </Toolbar>
            </AppBar>

            {isMobile &&
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    <div style={{ display: "flex", alignItems: "center", color: "#999", fontSize: 12, fontWeight: "400" }}>
                        <img src="https://elysolucoestecnologicas.com.br/images/ely-logo-blue.png" style={{ width: 100, marginLeft: 20 }} />
                    </div>
                    <List style={{ width: 315, padding: 20 }}>
                        <ListItem button style={{ background: currentPage === "home" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("home")}>
                            <ListItemText primary="Home" primaryTypographyProps={{ color: currentPage === "home" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "sobre" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("sobre")}>
                            <ListItemText primary="Sobre nós" primaryTypographyProps={{ color: currentPage === "sobre" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <Accordion>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <ListItemText primary="Soluções" primaryTypographyProps={{ color: "#464545", fontWeight: "400" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <ListItem button style={{ background: currentPage === "omnichannel" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("omnichannel")}>
                                    <ListItemText primary="Omnichannel" primaryTypographyProps={{ color: currentPage === "omnichannel" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                                </ListItem>
                                <ListItem button style={{ background: currentPage === "discador" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("discador")}>
                                    <ListItemText primary="Discador" primaryTypographyProps={{ color: currentPage === "discador" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                                </ListItem>
                                <ListItem button style={{ background: currentPage === "agente" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("agente")}>
                                    <ListItemText primary="Agente Virtual" primaryTypographyProps={{ color: currentPage === "agente" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                                </ListItem>
                            </AccordionDetails>
                        </Accordion>
                        <div style={{ height: 6 }} />
                        <Divider />
                        <div style={{ height: 24 }} />
                        <ListItem button style={{ background: currentPage === "contato" ? "#F2F2F2" : "#2B3AE7", borderRadius: 6 }} onClick={() => changePage("contato")}>
                            <PhoneOutlined style={{ marginRight: 14, color: currentPage === "contato" ? "#2B3AE7" : "#fff" }} />
                            <ListItemText primary="Contato" primaryTypographyProps={{ color: currentPage === "contato" ? "#2B3AE7" : "#fff" }} />
                        </ListItem>
                    </List>
                </Drawer>
            }


        </>
    );
};

export default SideMenu;
